<template>


    <h3>Tréninky</h3>
        <p>Trénovat u&nbsp;mě můžete přibližně v oblasti mezi Jičínem, Nymburkem a Mladou Boleslaví. V&nbsp;těchto třech městech také více či méně pravidelně pořádám i skupinové tréninky.</p>
    <h5>Zaměření</h5>
        <p>Mohu vám nabídnout pomoc s&nbsp;<b>výchovou rodinného psa</b> i se&nbsp;všeobecnou přípravou pro psí sporty - práci na&nbsp;soustředění, sebekontrole, přivolání, vzorné chůzi na vodítku, nácvik ošetření i učení konkrétních cviků nebo triků. 
        Vše takovou formou, abyste se bavili vy i váš pes a společný čas si všichni užili.</p>
        <p>Druhou oblastí na&nbsp;kterou se zaměřuji je řešení již existujícího <b>problémového chování</b>. Ráda budu vám i vašemu psovi pomáhat při práci se&nbsp;xa\strachem, agresí ke psům i lidem i dalšími problémy a pomůžu vám nastavit vaše soužití tak, aby fungovalo pro vás i pro psa.</p>
    <h5>Skupinové tréninky a socializační procházky</h5>
        <p>Skupinové tréninky v současné době nabízím v&nbsp;<a href="https://zkonymburk.blogspot.com/" target="_blank">ZKO Nymburk</a>, &nbsp;Mladé Boleslavi a Jičíně.</p>
        <p>Tréninky jsou zaměřené primárně přivolání, chůzi na prověšeném vodítku a sebekontrolu.</p>
        <p>U socializačních vycházek dbám na pohodu všech zúčastněných, jsou tedy vhodné i pro bázlivější nebo reaktivní psíky</p>
    <h3>Kurzy</h3>
        <p>Všechny mé kurzy obsahují teoretický úvod, který není pro účastníky povinný, zato je velmi užitečný.</p>
        <p>Cílem všech mých kurzů je usnadnit vám společný život s vaším psem nebo nastartovat vaši společnou sportovní kariéru.</p>
        <p>Za obrovskou výhodu kurzů považuji to, že&nbsp;tréninky na sebe navazují, postupujete v nich od jednodušších věcí ke složitějším a pokud se někde objeví zádrhel, snadno se k&nbsp;němu na příští lekci vrátíme a odstraníme ho. Máte také možsnost sledovat pokroky ostatních týmů a učit se od&nbsp;nich.
        Celý postup tak dává větší smysl a po&nbsp;celou dobu kurzy (i po&nbsp;něm) můžete stavět na&nbsp;teoretickém základu, ktterý získáte na&nbsp;úvodní hodině.</p>
    <h5>Základy rodinného psa 2.0 pro dospělé psy a puberťáky</h5>
        <p>Kurz vhodný pro všechny psíky, u&nbsp;kterých máte pocit, že byste si v&nbsp;běžných situacích mohli lépe rozumět. Možná jste v jejich štěněcím období neměli dostatek času, nebo vám vstoupil do&nbsp;cesty běžný život, 
        možná jste neměli dost podpory a informací, nebo jste si je přivedli domů už dospělé či dospívající. Nevadí, i dospělého psa slušnému chování naučíme, nebojte. V&nbsp;klidu a po&nbsp;dobrém.</p>
    <h5>Základy rodinného psa 2.0 pro štěňátka</h5>
        <p>V&nbsp;tomto kurzu je stejná náplň, jako v Základech rodinného psa pro&nbsp;dospělé psy a puberťáky. Více se však přizpůsobujeme teprve se vyvíjecímu mozku štěňátek - jejich větší potřebě zkoumat prostředí, kratší schopnosti se soustředit i rychleji nastupující únavě.</p>
    <h5>Pozornost a přivolání</h5>
        <p>Hry na přivolání, soustředění a sebekontrolu - inspirováno především Leslie McDevitt a Susan Garrett. Tento kurz je vhodný pro zlepšení pozornosti a přivolání jak u rodinných mazlíčků, tak pro sportovní psy, kteří mají na trénincích nebo zkouškách a závodech problém s udržením pozornosti.</p>
    <h5>Všeobecná sportovní přípravka</h5>
        <p>Tady předpokládám, že si se&nbsp;svým psíkem docela rozumíte a máte nějaké sportovní ambice. Ambicí může být i (jen) pravidelné ježdění na tréninky nějakého sportu a vybití nastřádané energie. Nebo možná už i tréninky nějakého sportu navštěvujete a zjistili jste, že by bylo fajn, aby váš psí parťák uměl věci, které nejsou úplně předmětem onoho sportu, ale vám to tréninky ztěžuje a váš trenér nemá čas, aby vám s tím během úzce zaměřených tréninků poradil.
        O&nbsp;co například může jít? Takové klidné čekání, než na&nbsp;vás přijde řada, bývá častým kamenem úrazu. Zapracujeme na&nbsp;motivaci, sebekontrole a podíváme se na&nbsp;základní chování, které se vám bude hodit snad u&nbsp;všech psích sportů :) </p>
    <h5>Pro&nbsp;reaktivce - s&nbsp;ostatními psy a lidmi v&nbsp;pohodě</h5>
        <p>Kurz pro psíky reagující přehnaně na podněty - budeme je učit, jak tyto situace lépe zvládat a vy se naučíte, jak je podpořit a pomoci jim.</p>
    <h5>Ošetření se souhlasem</h5>
        <p>Kurz pro všechny psí citlivky i macha, se kterými možná doma bojujete při&nbsp;vyndavání klíšťat, stříhání drápků, česání nebo kontrole uší. Možná váš problém začíná až při&nbsp;pohledu na dveře veteriny. Nebojte, i to se dá trpělivou prací změnit.</p>
    <h5>Pro&nbsp;psí důchodce a/nebo citlivky</h5>
        <p>Tady se zaměříme hlavně na udržení/rozvoj motoriky. Budeme hrát různé čuchací hry, řešit hlavolamy, šlapat po nerovných površích. Prostě se budeme věnovat věcem, které přinášejí dobrý pocit, zlepšují náladu, udržují mozek i tělo v běhu a nedovolí jim zakrnět a zároveň zvyšují sebedůveru psíka</p>
    <h5>Z&nbsp;bojínka hrdinou</h5>
        <p>Budeme hrát hry zvyšující sebevědomí. Budeme trénovat strategie, jak našemu psíkovi pomoci v situacích, které jsou pro něj obtížné, jak potkávat a míjet děsivé lidi nebo psy. Budeme se zabývat i strachem ze zvuků nebo divných předmětů.</p>
</template>
<script>
import { useMeta } from 'vue-meta'

export default {
  setup () {
    useMeta({ title: 'Člověk a pes spolu - trenér psů Zdenka Cihlářová - nabízené tréninky a kurzy' })
  },


}
</script>
